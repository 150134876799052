import React from 'react';

import './App.css';
import logo from './logo.svg';

export const App = () => {
  return (
    <main className="container">
      <img src={logo} alt="logo" className="logo" />
      <h1>Lingmates</h1>
      <p>In construction - please come again soon!</p>
    </main>
  );
};
